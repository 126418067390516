import * as Faq from "@src/shared/assets/images/stickyButtons/faq.inline.svg";
import * as Email from "@src/shared/assets/images/stickyButtons/mail.inline.svg";
import styled, { css } from "styled-components";

interface StickButtonProps {
    show: boolean;
}

export const FloatingButtons = styled.div<StickButtonProps>`
    display: none;
    position: fixed;
    width: 60px;
    right: 0;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    padding: 5px 0 5px 5px;
    opacity: 0;
    top: 40%;
    transform: translate3d(100%, -50%, 0);
    transition-property: visibility, transform, opacity;
    transition: 0.3s ease;
    visibility: hidden;

    ${({ show }) =>
        show &&
        css`
            opacity: 1;
            transform: translate3d(0, -50%, 0);
            visibility: visible;
        `}

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
    }
`;

export const EmailIcon = styled(Email)`
    width: 60px;
    height: 60px;
    fill: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin-bottom: 5px;
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition-duration: 0.3s;
    transition-property: color, background-color;
    transition-timing-function: ease;

    &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.porscheBankBlue};

        g {
            stroke: ${({ theme }) => theme.colors.porscheBankBlue};
        }
    }
`;

export const FaqIcon = styled(Faq)`
    width: 60px;
    height: 60px;
    fill: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.porscheBankBlue};
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition-duration: 0.3s;
    transition-property: color, background-color;
    transition-timing-function: ease;

    &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.porscheBankBlue};

        path {
            stroke: ${({ theme }) => theme.colors.porscheBankBlue};
        }

        g#Group {
            stroke: ${({ theme }) => theme.colors.porscheBankBlue};
            fill: ${({ theme }) => theme.colors.porscheBankBlue};
        }
    }
`;

export const IconLink = styled.a`
    display: block;
`;
