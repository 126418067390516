import useSnackbar from "@src/shared/context/snackbar/useSnackbar";
import * as React from "react";

import * as sc from "./Snackbar.sc";

const Snackbar: React.FunctionComponent = () => {
    const { current } = useSnackbar();

    return (
        <sc.Snackbar visible={Boolean(current)} type={current?.type}>
            {current?.message}
        </sc.Snackbar>
    );
};

export default Snackbar;
