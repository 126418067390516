import * as React from "react";

import { IconContainerLeft, IconContainerRight, InnerContainer, Root, Text } from "./Button.sc";

export enum ButtonWidth {
    Auto = "auto",
    FullWidth = "100%",
    FullWidthMobile = "fullWidthMobile",
}

export enum ButtonSize {
    Small = "small",
    Default = "default",
    Big = "big",
}

export enum ButtonVariant {
    Default = "default",
    Primary = "primary",
    Secondary = "secondary",
    Gray = "gray",
}

export enum ButtonFontWeight {
    Default = 400,
    Bold = 700,
}

interface IButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "size" | "ref" | "as"> {
    size?: ButtonSize;
    variant?: ButtonVariant;
    width?: ButtonWidth;
    leftContainer?: React.ReactNode;
    rightContainer?: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    type?: "submit";
    fontWeight?: ButtonFontWeight;
}

const Button: React.FunctionComponent<IButtonProps> = ({
    children,
    size = ButtonSize.Default,
    variant = ButtonVariant.Default,
    width = ButtonWidth.Auto,
    leftContainer,
    rightContainer,
    onClick,
    disabled,
    type,
    fontWeight,
    ...buttonProps
}) => {
    return (
        <Root size={size} variant={variant} width={width} onClick={onClick} disabled={disabled} type={type} {...buttonProps}>
            <InnerContainer>
                {leftContainer && (
                    <IconContainerLeft size={size} variant={variant}>
                        {leftContainer}
                    </IconContainerLeft>
                )}
                <Text size={size} variant={variant} fontWeight={fontWeight}>
                    {children}
                </Text>
                {rightContainer && (
                    <IconContainerRight size={size} variant={variant}>
                        {rightContainer}
                    </IconContainerRight>
                )}
            </InnerContainer>
        </Root>
    );
};
export default Button;
