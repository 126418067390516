import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./Footer.sc";

const Footer: React.FunctionComponent = () => {
    const intl = useIntl();
    return (
        <>
            <sc.BrandLinks>
                <sc.BrandLink href="https://www.porscheleasing.bg/marki/volkswagen/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/vw.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={70}
                        height={70}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porscheleasing.bg/marki/audi/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/audi-bg.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={70}
                        height={10}
                    />
                </sc.BrandLink>
                <sc.BrandLinkVwnBG href="https://www.porscheleasing.bg/marki/vw-lekotovarni/" target="_blank">
                    <sc.BrandLogoVwnBG
                        data-src="/footerBrands/vwn-bg.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={70}
                        height={70}
                    />
                </sc.BrandLinkVwnBG>
                <sc.BrandLink href="https://www.porscheleasing.bg/marki/das-weltauto/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/weltauto.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={70}
                        height={10}
                    />
                </sc.BrandLink>
            </sc.BrandLinks>
            <sc.FooterBottom>
                <sc.FooterBottomLinksWrapper>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.bg/obshi-usloviya-za-lizing/">
                        <FormattedMessage id="leaseme.footer.agb" defaultMessage="AGB" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.bg/za-klienti/chesto-zadavani-vprosi-faq/">
                        <FormattedMessage id="leaseme.footer.faq" defaultMessage="FAQ" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.bg/kontakti/">
                        <FormattedMessage id="leaseme.footer.contact" defaultMessage="Kontakt" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.bg/copyright/">
                        <FormattedMessage id="leaseme.footer.imprint" defaultMessage="Impressum" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.bg/privacy-policy/">
                        <FormattedMessage id="leaseme.footer.dataPrivacy" defaultMessage="Datenschutz" />
                    </sc.FooterBottomLink>
                </sc.FooterBottomLinksWrapper>
                <sc.FooterText>
                    <FormattedMessage
                        id="leaseme.footer.copyright"
                        defaultMessage="{copyright} {year} Porsche Bank Aktiengesellschaft"
                        values={{
                            copyright: "©",
                            year: new Date().getFullYear(),
                        }}
                    />
                </sc.FooterText>
            </sc.FooterBottom>
        </>
    );
};

export default Footer;
