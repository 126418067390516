import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./Footer.sc";

const Footer: React.FunctionComponent = () => {
    const intl = useIntl();
    return (
        <>
            <sc.BrandLinks>
                <sc.BrandLink href="http://www.volkswagen.si/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/vw.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="http://www.audi.si/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/audi.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="http://www.seat.si/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/seat.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.cupraofficial.si/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/cupra.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="http://www.skoda.si/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/skoda.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="http://www.porsche.com/international/_slovenia_/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/porsche.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="http://www.vw-gospodarska.si/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/vwn-si.svg"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={70}
                        height={70}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="http://www.entry.man.eu/si/si/index.html" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/man.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="http://scania.si/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/scania.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="http://www.dasweltauto.si/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/weltauto.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
            </sc.BrandLinks>
            <sc.FooterBottom>
                <sc.FooterBottomLinksWrapper>
                    <sc.FooterBottomLink
                        target="_blank"
                        href="https://www.porscheleasing.si/podjetje/splosni-nabavni-pogoji-splosni-pogoji-poslovanja/"
                    >
                        <FormattedMessage id="leaseme.footer.agb" defaultMessage="AGB" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.si/podjetje/pogosta-vprasanja/">
                        <FormattedMessage id="leaseme.footer.faq" defaultMessage="FAQ" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.si/kontakt">
                        <FormattedMessage id="leaseme.footer.contact" defaultMessage="Kontakt" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.si/impressum">
                        <FormattedMessage id="leaseme.footer.imprint" defaultMessage="Impressum" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.si/varstvo-osebnih-podatkov-2">
                        <FormattedMessage id="leaseme.footer.dataPrivacy" defaultMessage="Datenschutz" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.si/podjetje/skladnost-poslovanja/">
                        <FormattedMessage id="leaseme.footer.businessCompliance" defaultMessage="Geschäftskonformität" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porscheleasing.si/pravilnik-o-piskotkih/">
                        <FormattedMessage id="leaseme.footer.cookiePolicy" defaultMessage="Cookie-Richtlinie" />
                    </sc.FooterBottomLink>
                </sc.FooterBottomLinksWrapper>
                <sc.FooterText>
                    <FormattedMessage
                        id="leaseme.footer.copyright"
                        defaultMessage="{copyright} {year} Porsche Bank Aktiengesellschaft"
                        values={{
                            copyright: "©",
                            year: new Date().getFullYear(),
                        }}
                    />
                </sc.FooterText>
            </sc.FooterBottom>
        </>
    );
};

export default Footer;
