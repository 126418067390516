import * as Chevron from "@src/shared/assets/images/chevron.inline.svg";
import { Link } from "gatsby";
import styled, { css } from "styled-components";

export const Root = styled.div`
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
`;

interface IContentWrapperProps {
    fullWidth?: boolean;
    marginTop?: number;
}

export const ContentWrapper = styled.div<IContentWrapperProps>`
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-width: 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;
    }

    ${({ fullWidth }) =>
        !fullWidth &&
        css`
            max-width: 1400px;
        `}

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        flex-direction: row;
    }
`;

export const SideBar = styled.div`
    width: 100%;
    z-index: 10;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        width: 324px;
    }
`;

export const MainContent = styled.main`
    flex: 1;
    min-width: 0;
`;

interface ButtonWrapperProps {
    marginTop?: number;
    fullWidth?: boolean;
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
    margin: 0 auto;
    margin-top: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundLightGray};
    display: flex;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;
    }

    ${({ fullWidth }) =>
        !fullWidth &&
        css`
            max-width: 1400px;
        `}
`;

export const ChevronLeft = styled(Chevron)`
    transform: rotate(180deg);
    margin-right: 5px;
`;

export const BackButton = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    display: flex;
    align-items: center;
    padding: 5px 0 20px 20px;
    line-height: 30px;
    font-size: 18px;
`;
