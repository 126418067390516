import { ITheme } from "@src/shared/theme/types";
import styled, { css, ThemedStyledProps } from "styled-components";

import { ButtonFontWeight, ButtonSize, ButtonVariant, ButtonWidth } from "./Button";

interface IRootStyleProps {
    size: ButtonSize;
    variant: ButtonVariant;
    width?: ButtonWidth;
    fontWeight?: ButtonFontWeight;
}

export const Root = styled.button<IRootStyleProps>`
    display: inline-block;
    border: ${({ theme, variant }) => (variant === ButtonVariant.Secondary ? `1px solid ${theme.colors.porscheBankBlue}` : 0)};
    background-color: ${(props) => getBackgroundColor(props)};
    color: ${(props) => getColor(props)};
    width: ${(props) => (props.width === "fullWidthMobile" ? "100%" : props.width)};

    :hover {
        cursor: pointer;
    }

    :disabled {
        background-color: ${({ theme }) => theme.colors.gray4};
    }

    ${(props) => {
        const paddingHorizontal = props.size == ButtonSize.Big ? 24 : 12;
        const paddingVertical = props.size == ButtonSize.Big ? 16 : 12;

        return css`
            padding-top: ${paddingVertical}px;
            padding-bottom: ${paddingVertical}px;
            padding-left: ${paddingHorizontal}px;
            padding-right: ${paddingHorizontal}px;
        `;
    }}

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        ${(props) =>
            props.width === "fullWidthMobile" &&
            css`
                width: auto;
            `};
    }
`;

const getBackgroundColor = ({ theme, variant }: ThemedStyledProps<IRootStyleProps, ITheme>) => {
    switch (variant) {
        case ButtonVariant.Primary:
            return theme.colors.porscheBankBlue;
        case ButtonVariant.Secondary:
            return theme.colors.white;
        case ButtonVariant.Gray:
            return theme.colors.gray;
        case ButtonVariant.Default:
        default:
            return "transparent";
    }
};

const getColor = ({ theme, variant }: ThemedStyledProps<IRootStyleProps, ITheme>) => {
    switch (variant) {
        case ButtonVariant.Default:
        case ButtonVariant.Secondary:
            return theme.colors.porscheBankBlue;
        case ButtonVariant.Gray:
            return theme.colors.typo;
        default:
            return "white";
    }
};

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

interface IIconContainerProps {
    size: ButtonSize;
    variant: ButtonVariant;
}

const IconContainer = styled.div<IIconContainerProps>`
    svg {
        display: block;
        width: ${(props) => getIconSize(props)};
        height: ${(props) => getIconSize(props)};
        fill: ${(props) => getColor(props)};
    }
`;

export const IconContainerLeft = styled(IconContainer)`
    margin-right: 8px;
`;

export const IconContainerRight = styled(IconContainer)`
    margin-left: 8px;
`;

const getIconSize = ({ size }: IIconContainerProps) => {
    const iconSizes = {
        [ButtonSize.Small]: 12,
        [ButtonSize.Default]: 16,
        [ButtonSize.Big]: 20,
    };

    return `${iconSizes[size]}px`;
};

export const Text = styled.span<IRootStyleProps>`
    font-size: ${(props) => (props.size == ButtonSize.Default ? 16 : props.size == ButtonSize.Small ? 14 : 19)}px;

    font-weight: ${({ fontWeight }) => fontWeight};
    white-space: nowrap;
    display: flex;
    align-items: center;
`;
