import styled from "styled-components";

export const BrandLinks = styled.div`
    background-color: ${({ theme }) => theme.colors.lightGray};
    text-align: center;
    padding: 20px 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 30px 70px;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 30px 140px;
    }
`;

export const BrandLinkVwnBG = styled.a`
    display: inline-block;
    width: 70px;
    height: 70px;
    overflow: hidden;
    filter: grayscale(100%);

    &:hover {
        filter: grayscale(0);
    }
`;

export const BrandLogoVwnBG = styled.img.attrs(() => ({
    className: "lazyload",
}))`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const BrandLink = styled.a`
    display: inline-block;
    width: 70px;
    height: 70px;
    overflow: hidden;
    filter: grayscale(100%);

    &:hover {
        filter: grayscale(0);
    }
`;

export const BrandLogo = styled.img.attrs(() => ({
    className: "lazyload",
}))`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const FooterBottom = styled.div`
    background-color: ${({ theme }) => theme.colors.typo};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 30px 70px;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 30px 140px;
    }
`;

export const FooterBottomLink = styled.a`
    color: white;
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
`;

export const FooterBottomLinksWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: 20px;
    gap: 20px;
    row-gap: 5px;
`;

export const FooterText = styled.span`
    color: white;
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
`;
