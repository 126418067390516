import * as MenuIcon from "@src/shared/assets/images/menuIcon.inline.svg";
import * as CloseIcon from "@src/shared/assets/images/times.inline.svg";
import { Link } from "gatsby";
import styled from "styled-components";

export const MenuLink = styled(Link)`
    padding: 10px 0;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-decoration: none;
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;

    :hover,
    &.activeLink {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.porscheBankBlue};
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        line-height: 60px;
        font-size: 20px;
        height: 60px;
        padding: 0;
        margin: 0 25px;
        color: ${({ theme }) => theme.colors.white};

        &.activeLink {
            color: ${({ theme }) => theme.colors.white};
            font-weight: 500;
            border-bottom: 3px solid ${({ theme }) => theme.colors.white};
        }
        :first-child {
            margin-left: 0;
        }
        :last-child {
            margin-right: 0;
        }
    }
`;

export const Dropdown = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    line-height: 46px;
    padding: 20px 15px;
    text-decoration: none;
    background: ${({ theme }) => theme.colors.white};
    position: fixed;
    top: 68px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    overflow: auto;

    ${MenuLink} {
        display: block;
        padding: 0 10px;
        margin: 5px -10px;

        :first-child {
            border-top: none;
        }
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;

export const MenuDesktopOuter = styled.div`
    display: none;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
        background: ${({ theme }) => theme.colors.porscheBankBlue};
    }
`;

export const MenuDesktopInner = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;

    ${MenuLink}:first-child {
        padding-left: 0;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0;
    }
`;

export const MenuMobile = styled.div`
    display: inline-block;
    max-width: 300px;
    box-sizing: border-box;
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.porscheBankBlue};
    color: ${({ theme }) => theme.colors.white};
    flex-grow: 1;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;

export const Selected = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const SelectIcon = styled.div``;

export const CustomCloseIcon = styled(CloseIcon)`
    stroke: ${({ theme }) => theme.colors.white};
    width: 24px;
    height: 24px;
`;

export const CustomMenuIcon = styled(MenuIcon)`
    stroke: ${({ theme }) => theme.colors.white};
    width: 24px;
    height: 24px;
`;
