import React from "react";

import { EmailIcon, FaqIcon, FloatingButtons, IconLink } from "./StickyButtons.sc";

interface StickyButtonsProps {
    mailLink: string;
    faqLink: string;
}

const StickyButtons: React.FC<StickyButtonsProps> = ({ mailLink, faqLink }) => {
    const [showStickyButtons, setShowStickyButtons] = React.useState<boolean>(false);

    React.useEffect(() => {
        const onScroll = () => {
            setShowStickyButtons(window.scrollY > 120);
        };

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <FloatingButtons show={showStickyButtons}>
            <IconLink href={mailLink} target="_blank">
                <EmailIcon />
            </IconLink>
            <IconLink href={faqLink} target="_blank">
                <FaqIcon />
            </IconLink>
        </FloatingButtons>
    );
};

export default StickyButtons;
