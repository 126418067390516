import FooterAT from "@src/AT/footer/Footer";
import FooterBG from "@src/BG/footer/Footer";
import { getConfig } from "@src/config";
import FooterHU from "@src/HU/footer/Footer";
import FooterRO from "@src/RO/footer/Footer";
import FooterSI from "@src/SI/footer/Footer";
import * as React from "react";

const Footer: React.FunctionComponent = () => {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return <FooterBG />;
        case "car4me.ro":
            return <FooterRO />;
        case "car4me.si":
            return <FooterSI />;
        case "car4me.porschefinance.hu":
            return <FooterHU />;
        default:
            return <FooterAT />;
    }
};

export default Footer;
