import { SnackbarType } from "@src/shared/context/snackbar/SnackbarContext";
import { ITheme } from "@src/shared/theme/types";
import styled from "styled-components";

export const Snackbar = styled.div<{ visible: boolean; type?: SnackbarType }>`
    position: fixed;
    z-index: 20;
    bottom: ${(props) => (props.visible ? "10px" : "0")};
    left: 0;
    right: 0;
    color: #fff;
    border-radius: 3px;
    display: ${(props) => (props.visible ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    font-size: 16px;

    max-width: 500px;
    min-width: 200px;
    animation: slide-in-bottom 0.5s ease;
    transition: all 0.4s ease;
    padding: 10px 20px;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transform: ${(props) => (props.visible ? "translateY(0)" : "translateY(100%)")};
    background: ${({ type, theme }) => getColor(type, theme)};
    margin: 0 auto;

    @keyframes slide-in-bottom {
        from {
            transform: translateY(100%);
        }

        to {
            transform: translateY(0);
        }
    }
`;

const getColor = (type = SnackbarType.info, theme: ITheme) => {
    switch (type) {
        case SnackbarType.success:
            return theme.colors.success;
        case SnackbarType.error:
            return theme.colors.error;
        default:
            return theme.colors.info;
    }
};
