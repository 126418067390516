import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./Footer.sc";

const Footer: React.FunctionComponent = () => {
    const intl = useIntl();
    return (
        <>
            <sc.BrandLinks>
                <sc.BrandLink href="https://www.porschebank.at/marken/volkswagen/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/vw.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/marken/vw-nutzfahrzeuge/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/vwn.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/marken/skoda/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/skoda.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/marken/seat/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/seat.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/produkte/leasing/leasing-angebote/cupra-leasing-angebote/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/cupra.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/marken/audi/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/audi.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/marken/lamborghini/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/lamborghini.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/marken/bentley/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/bentley.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/marken/ducati/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/ducati.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/marken/porsche/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/porsche.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/marken/das-weltauto/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/weltauto.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/porsche-bank.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.at/ueber-uns/nachhaltigkeit/nachhaltige-produkte/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/moon.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
            </sc.BrandLinks>
            <sc.FooterBottom>
                <sc.FooterBottomLinksWrapper>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.at/agb/">
                        <FormattedMessage id="leaseme.footer.agb" defaultMessage="AGB" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.at/kontakt/faq/">
                        <FormattedMessage id="leaseme.footer.faq" defaultMessage="FAQ" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.at/kontakt/">
                        <FormattedMessage id="leaseme.footer.contact" defaultMessage="Kontakt" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.at/impressum/">
                        <FormattedMessage id="leaseme.footer.imprint" defaultMessage="Impressum" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.at/datenschutz/">
                        <FormattedMessage id="leaseme.footer.dataPrivacy" defaultMessage="Datenschutz" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.at/kundenservice/finanzlexikon/">
                        <FormattedMessage id="leaseme.footer.financialLexicon" defaultMessage="Finanzlexikon" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.at/kundenservice/haendlersuche/">
                        <FormattedMessage id="leaseme.footer.dealerSearch" defaultMessage="Händlersuche" />
                    </sc.FooterBottomLink>
                </sc.FooterBottomLinksWrapper>
                <sc.FooterText>
                    <FormattedMessage
                        id="leaseme.footer.copyright"
                        defaultMessage="{copyright} {year} Porsche Bank Aktiengesellschaft"
                        values={{
                            copyright: "©",
                            year: new Date().getFullYear(),
                        }}
                    />
                </sc.FooterText>
            </sc.FooterBottom>
        </>
    );
};

export default Footer;
