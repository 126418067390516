import styled from "styled-components";

export const BrandLinks = styled.div`
    background-color: ${({ theme }) => theme.colors.lightGray};
    text-align: center;
    padding: 20px 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 30px 70px;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 30px 140px;
    }
`;

export const BrandLink = styled.a`
    display: inline-block;
    width: 75px;
    height: 54px;
    overflow: hidden;
    margin: 0 10px;
`;

export const BrandLogo = styled.img.attrs(() => ({
    className: "lazyload",
}))`
    position: relative;
    width: 100%;
    top: -62px;

    &:hover {
        top: -2px;
    }
`;

export const FooterBottom = styled.div`
    background-color: ${({ theme }) => theme.colors.typo};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 30px 70px;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 30px 140px;
    }
`;

export const FooterBottomLink = styled.a`
    color: white;
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
`;

export const FooterBottomLinksWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: 20px;
    gap: 20px;
    row-gap: 5px;
`;

export const FooterText = styled.span`
    color: white;
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
`;
