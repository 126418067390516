import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./Footer.sc";

const Footer: React.FunctionComponent = () => {
    const intl = useIntl();
    return (
        <>
            <sc.BrandLinks>
                <sc.BrandLink href="https://www.porschebank.ro/ro/marci/volkswagen" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/vw.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.ro/ro/marci/vw-autovehicule-comerciale" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/vwn-ro.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.ro/ro/marci/audi" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/audi-bg.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.ro/ro/marci/seat" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/seat.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.ro/ro/marci/skoda" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/skoda.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.ro/ro/marci/porsche" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/porsche.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="#">
                    <sc.BrandLogo
                        data-src="/footerBrands/bentley.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="#">
                    <sc.BrandLogo
                        data-src="/footerBrands/lamborghini.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="#">
                    <sc.BrandLogo
                        data-src="/footerBrands/bugatti.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="#">
                    <sc.BrandLogo
                        data-src="/footerBrands/cupra.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.ro/ro/marci/man-finance" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/man.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschebank.ro/ro/marci/das-weltauto" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/weltauto.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
            </sc.BrandLinks>
            <sc.FooterBottom>
                <sc.FooterBottomLinksWrapper>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.ro/ro/conditii-contractuale-generale">
                        <FormattedMessage id="leaseme.footer.agb" defaultMessage="AGB" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.ro/ro/relatii-clienti/contact">
                        <FormattedMessage id="leaseme.footer.contact" defaultMessage="Kontakt" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.ro/ro/impressum">
                        <FormattedMessage id="leaseme.footer.imprint" defaultMessage="Impressum" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschebank.ro/ro/protectia-datelor">
                        <FormattedMessage id="leaseme.footer.dataPrivacy" defaultMessage="Datenschutz" />
                    </sc.FooterBottomLink>
                </sc.FooterBottomLinksWrapper>
                <sc.FooterText>
                    <FormattedMessage
                        id="leaseme.footer.copyright"
                        defaultMessage="{copyright} {year} Porsche Bank Aktiengesellschaft"
                        values={{
                            copyright: "©",
                            year: new Date().getFullYear(),
                        }}
                    />
                </sc.FooterText>
            </sc.FooterBottom>
        </>
    );
};

export default Footer;
