import { useLocation } from "@reach/router";
import { GQLFinanceType } from "@src/graphql.apollo.generated";
import React from "react";
import { FormattedMessage } from "react-intl";

import useSearchFilter from "../context/searchfilter/useSearchFilter";
import isLandingPage from "../utils/isLandingPage";
import isModelPage from "../utils/isModelPage";
import isVehicleDetailPage from "../utils/isVehicleDetailPage";
import * as sc from "./Navbar.sc";

interface NavbarProps {
    dropdownOpen: boolean;
    toggleDropdown: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ toggleDropdown, dropdownOpen }) => {
    const { filter } = useSearchFilter();
    const searchUrl =
        filter.financeType === GQLFinanceType.leasing
            ? "/leasing/"
            : filter.financeType === GQLFinanceType.credit
            ? "/kredit/"
            : filter.financeType === GQLFinanceType.openEndLeasing
            ? "/openEndLeasing/"
            : "/closedEndLeasing/";

    const location = useLocation();

    const isInSearch = isLandingPage(location) || isModelPage(location) || isVehicleDetailPage(location);

    return (
        <>
            <sc.MenuDesktopOuter>
                <sc.MenuDesktopInner>
                    <sc.MenuLink to="/" activeClassName="activeLink">
                        <FormattedMessage id="leaseme.header.home" defaultMessage="LEASE ME HOME" />
                    </sc.MenuLink>

                    <sc.MenuLink to={searchUrl} className={isInSearch ? "activeLink" : undefined}>
                        <FormattedMessage id="leaseme.header.searchOffers" defaultMessage="Angebote suchen" />
                    </sc.MenuLink>
                </sc.MenuDesktopInner>
            </sc.MenuDesktopOuter>
            <sc.MenuMobile>
                <sc.Selected onClick={toggleDropdown}>
                    <sc.SelectIcon>{dropdownOpen ? <sc.CustomCloseIcon /> : <sc.CustomMenuIcon />}</sc.SelectIcon>
                </sc.Selected>
            </sc.MenuMobile>
        </>
    );
};

export default Navbar;
