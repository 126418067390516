import NavbarAT from "@src/AT/header/Navbar";
import { getConfig } from "@src/config";
import HeaderLogo from "@src/shared/assets/images/porsche_bank_logo_white.inline.svg";
import Navbar from "@src/shared/header/Navbar";
import NavbarDropdown from "@src/shared/header/NavbarDropdown";
import * as React from "react";

import * as sc from "./Header.sc";

interface Props {
    mainMenu?: any;
}

const Header: React.FunctionComponent<Props> = () => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <sc.Container>
            {getConfig("scope").domain === "lease-me.porschebank.at" ? (
                <NavbarAT />
            ) : (
                <>
                    <sc.Root dropdownOpen={dropdownOpen}>
                        <sc.StyledLogoLink to="/">
                            <HeaderLogo />
                        </sc.StyledLogoLink>

                        <Navbar toggleDropdown={toggleDropdown} dropdownOpen={dropdownOpen} />
                    </sc.Root>
                    <sc.RootTriangle />
                    <NavbarDropdown dropdownOpen={dropdownOpen} />
                </>
            )}
        </sc.Container>
    );
};

export default Header;
