import { getConfig } from "@src/config";
import Snackbar from "@src/shared/snackbar/Snackbar";
import StickyButtons from "@src/shared/stickyButtons/StickyButtons";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import { BackButton, ButtonWrapper, ChevronLeft, ContentWrapper, MainContent, Root, SideBar } from "./Master.sc";

interface Props {
    mainMenu?: any;
    sideBar?: React.ReactNode;
    fullWidth?: boolean;
    marginTop?: number;
    backButtonHome?: boolean;
}

const getStickyButtonLinks = () => {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return {
                faqLink: "https://www.porscheleasing.bg/za-klienti/chesto-zadavani-vprosi-faq",
                contactLink: "https://www.porscheleasing.bg/kontakti",
            };
        case "car4me.si":
            return {
                faqLink: "https://www.porscheleasing.si/podjetje/pogosta-vprasanja/",
                contactLink: "https://www.porscheleasing.si/kontakt",
            };
        case "car4me.porschefinance.hu":
            return {
                faqLink: "https://www.porschefinance.hu/kapcsolat/gyakori-kerdesek/",
                contactLink: "https://www.porschefinance.hu/ugyintezes/elerhetosegek/",
            };
        default:
            return {
                faqLink: "https://www.porschebank.at/kundenservice/haeufige-fragen-faq",
                contactLink: "https://www.porschebank.at/kontakt",
            };
    }
};

const Master: React.FunctionComponent<Props> = ({ mainMenu, sideBar, children, fullWidth, marginTop, backButtonHome }) => {
    const { faqLink, contactLink } = getStickyButtonLinks();

    return (
        <Root>
            <Header mainMenu={mainMenu} />
            <StickyButtons mailLink={contactLink} faqLink={faqLink} />
            {backButtonHome && (
                <ButtonWrapper fullWidth={fullWidth} marginTop={marginTop}>
                    <BackButton to={"/"}>
                        <ChevronLeft />
                        <FormattedMessage id="leaseme.searchResult.back" defaultMessage="zurück" />
                    </BackButton>
                </ButtonWrapper>
            )}
            <ContentWrapper fullWidth={fullWidth} marginTop={backButtonHome ? 20 : marginTop}>
                <Snackbar />
                {sideBar && <SideBar>{sideBar}</SideBar>}
                <MainContent>{children}</MainContent>
            </ContentWrapper>
            <div>
                <Footer />
            </div>
        </Root>
    );
};

export default Master;
