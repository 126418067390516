import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./Footer.sc";

const Footer: React.FunctionComponent = () => {
    const intl = useIntl();
    return (
        <>
            <sc.BrandLinks>
                <sc.BrandLink href="https://www.volkswagen.hu/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/vw.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.audi.hu/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/audi.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.skoda.hu/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/skoda.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.seat.hu/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/seat.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.cupraofficial.hu/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/cupra.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.vwh.hu/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/vwn-hu.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.porschehungaria.hu/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/porsche.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://budapest.bentleymotors.com/eu/en/home" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/bentley.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.man.eu/hu/hu/fooldal.html" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/man.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
                <sc.BrandLink href="https://www.dasweltauto.hu/" target="_blank">
                    <sc.BrandLogo
                        data-src="/footerBrands/weltauto.png"
                        alt={intl.formatMessage({
                            id: "leaseme.logos.label",
                            defaultMessage: "Markenlogo",
                        })}
                        width={75}
                        height={124}
                    />
                </sc.BrandLink>
            </sc.BrandLinks>
            <sc.FooterBottom>
                <sc.FooterBottomLinksWrapper>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschefinance.hu/ugyintezes/szerzodeses-dokumentumok/">
                        <FormattedMessage id="leaseme.footer.gtc" defaultMessage="GTC" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschefinance.hu/ugyintezes/elerhetosegek/">
                        <FormattedMessage id="leaseme.footer.contact" defaultMessage="Kontakt" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschefinance.hu/vallalat/kozzetetel/">
                        <FormattedMessage id="leaseme.footer.publication" defaultMessage="Publikation" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschefinance.hu/impresszum/">
                        <FormattedMessage id="leaseme.footer.imprint" defaultMessage="Impressum" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschefinance.hu/adatvedelem/">
                        <FormattedMessage id="leaseme.footer.dataProtection" defaultMessage="Datenschutz" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschefinance.hu/vallalat/kapcsolat/rolunk/compliance/">
                        <FormattedMessage id="leaseme.footer.compliance" defaultMessage="Compliance" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.mnb.hu/fogyasztovedelem">
                        <FormattedMessage id="leaseme.footer.financialNavigator" defaultMessage="Finanznavigator" />
                    </sc.FooterBottomLink>
                    <sc.FooterBottomLink target="_blank" href="https://www.porschefinance.hu/vallalat/ugyintezes/panaszkezeles/">
                        <FormattedMessage id="leaseme.footer.complaintManagement" defaultMessage="Beschwerde Management" />
                    </sc.FooterBottomLink>
                </sc.FooterBottomLinksWrapper>
                <sc.FooterText>
                    <FormattedMessage
                        id="leaseme.footer.copyright"
                        defaultMessage="{copyright} {year} Porsche Bank Aktiengesellschaft"
                        values={{
                            copyright: "©",
                            year: new Date().getFullYear(),
                        }}
                    />
                </sc.FooterText>
            </sc.FooterBottom>
        </>
    );
};

export default Footer;
