import * as React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

const Navbar: React.FC = () => {
    return (
        <>
            <Helmet>
                <script async src="https://www.porschebank.at/dist/porschebank-nav.js" />
            </Helmet>
            <Root>
                <porschebank-nav
                    activeId="id-1073"
                    baseUrl="https://www.porschebank.at"
                    language="de"
                    languages="de,en"
                    languageLinks="https://www.porschebank.at/,https://www.porschebank.at/en/"
                    site="at"
                    siteUrl="https://www.porschebank.at/"
                />
            </Root>
        </>
    );
};

const Root = styled.header`
    box-sizing: border-box;
    height: 92px;
    padding: 0.25rem 0 calc(0.25rem + 2vw);

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0.875rem 0 calc(0.875rem + 2vw);
    }
`;

export default Navbar;
