import { Link } from "gatsby";
import styled from "styled-components";

export const Container = styled.div`
    height: 68px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        height: 140px;
    }
`;

interface IRoot {
    dropdownOpen: boolean;
}

export const Root = styled.header<IRoot>`
    position: ${({ dropdownOpen }) => (dropdownOpen ? "fixed" : "absolute")};
    width: 100%;
    z-index: 5;
    background-color: ${({ theme }) => theme.colors.porscheBankBlue};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        position: relative;
        justify-content: flex-start;
        padding: 20px 70px;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 20px 140px;
    }
`;

export const StyledLogoLink = styled(Link)`
    color: ${({ theme }) => theme.colors.white};
    padding-right: 40px;

    svg {
        width: 44px;
        height: 24px;

        ${({ theme }) => theme.breakpoints.md.mediaquery} {
            width: auto;
            height: auto;
        }
    }
`;

export const RootTriangle = styled.div`
    display: none;
    background-color: ${({ theme }) => theme.colors.porscheBankBlue};
    position: relative;
    margin-left: -10%;
    height: 50px;
    width: 120%;
    bottom: 25px;
    transform: rotate(-1.5deg);
    z-index: -1;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: block;
    }
    ${({ theme }) => theme.breakpoints.xxl.mediaquery} {
        transform: rotate(-0.5deg);
    }
`;
